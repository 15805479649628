<template>
	<div class="part-pdf"
		v-click-outside="onClickOutside">
			<div class="title">
				{{ this.pdfName }}
			</div>

			<div class="pdf-display">
				<div class="pdf-view">
					<div class="viewer">
						<v-spacer />
							<vue-pdf-app
								:pdf="this.viewPDF == null ? '' : this.viewPDF">
							</vue-pdf-app>
						<v-spacer />
					</div>
				</div>

				<div class="parts-list">
					<div class="part-line">
						<h4>Parts List:</h4>
						
						<v-spacer />
						
						<div class="show-pricing"
							v-if="this.$store.getters['authentication/GET_AUTHENTICATED']">
							<v-switch
								v-model="showPricing" 
								hide-details="auto"
								@change="ChangeShowPricing()"
								label="Show Pricing"/>
						</div>
					</div>

					<v-divider 
						style="margin-bottom: 5px;"
						light/>

					<div class="part-container"
						v-for="part, index in parts"
						:key="index">
						<!-- Part ID -->
						<div class="part-line">
							<div class="search-fields"
								style="width: 120px;">
								<v-text-field
									class="centered-input"
									style="	background-color: #FFEBCD;"
									type="text"
									v-model="part.item"
									label="Item"
									hide-details="auto"
									readonly
									outlined
									dense/>
							</div>
						</div>						

						<!-- Part Quantity, Part Number And Add To Cart -->
						<div class="part-line">
							<div class="search-fields"
								style="flex-grow: 0;">
								<v-text-field
									class="centered-input"
									style="	background-color: #E0FFFF; text-align: right;width: 100px;"
									type="number"
									min="1"
									:suffix="part.uom"
									v-model="part.quantity"
									label="Qty"
									hide-details="auto"
									outlined
									dense/>
							</div>

							<div class="search-fields"
								style="flex-grow: 1;">
								<v-text-field
									style="background-color: #E0FFFF;"
									type="text"
									v-model="part.number"
									label="Part Number"
									hide-details="auto"
									readonly
									outlined
									dense/>
							</div>

							<div class="search-fields"
								v-if="showCart"
								style="flex-grow: 0;">
								<v-btn
									icon
									:disabled="!showCart(part)"
									@click="addToCart(part)">
									<v-icon>
										mdi-cart-plus
									</v-icon>
								</v-btn>
							</div>

						</div>

						<!-- Part Description -->
						<div class="part-line">
							<div class="search-fields"
								style="width: 100%;">
								<v-text-field
									style="	background-color: #E0FFFF;"
									type="text"
									v-model="part.description"
									label="Part Description"
									hide-details="auto"
									readonly
									outlined
									dense/>
							</div>
						</div>

						<!-- Part Pricing -->
						<div class="part-line"
							v-if="$store.getters['authentication/GET_AUTHENTICATION'] && part.retail > 0 && part.sellable && showCart && showPrice">
							<div class="search-fields"
								style="width: 100%;">
								<table style="width: 100%;">
									<tr>
										<td class="price">Dealer: ${{ getDealer(part.retail) }}</td>
										<td class="price">Retail: ${{ part.retail.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
										<td></td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
import { EventBus } from '../../javascript/event-bus'
import pdf from 'vue-pdf'
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import vClickOutside from 'v-click-outside'

export default {
	name: 'PartPdf',
	components: {
		pdf,
		VuePdfApp
	},
    directives: {
      clickOutside: vClickOutside.directive
    },
	props: {
		pdfID: Number
	},
	data () {
		return {
			loading: false,
			pdfName: "",
			pdfFile: "",
			parts: [],
			viewPDF: "",
			showPricing: false,
		}
	},
	methods: {
		getPDF() {
			if (this.pdfID == null || this.pdfID == -1) {
				return
			}
			
			this.loading = true
			var formData = new FormData();

			if (this.pdfID != -1) {
				formData.append("id", this.pdfID)
			}

			formData.append("vid", this.$store.getters['authentication/GET_VID'])

			axios.post(`${this.$config.api}/api.php?cmd=getPDF`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					this.loading = false;

					if (response.data.action == "login"){
						this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
						this.$store.dispatch('authentication/SET_CREDENTIALS', {})
					}

					return
				}

				this.pdfName = response.data.name
				this.active = response.data.active == 1 ? true : false
				this.pdfFile = response.data.file
				this.showPDFs = false
				this.parts = response.data.parts
				this.viewPDF = `${this.$config.pdf_dir}${encodeURIComponent(this.pdfFile)}`
				
				//console.log(this.parts)

				this.loading = false
			})
			.catch(e => {
				this.$debug.error("parts-pdfs", "getPDF", `API Error (${e})`)
				this.loading = false;
			})
		},
		onClickOutside (event) {
			this.$parent.pdfID = -1
		},
		getDealer(retail) {
			var decimal = this.$store.getters['cart/GET_DISCOUNT'].decimal
			var dealer = (retail * decimal).toFixed(2)
			//console.log(`Dealer: ${dealer} Decimal: ${decimal}`)
			return dealer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		showCart(part) {
			return part.sellable && this.$store.getters['authentication/GET_CREDENTIALS'].authenticated
		},
		addToCart(part) {
			part.dealer = this.getDealer(part.retail)
			
			if (part.quantity < 1) {
				return
			}

			this.$store.dispatch("cart/ADD_PART", {
					id: part.partid,
					part: part.number,
					quantity: part.quantity,
					description: part.description,
					retail: parseFloat(part.retail).toFixed(2),
					dealer: parseFloat(part.dealer).toFixed(2)
				})

			this.$cookie.set("cart", JSON.stringify(this.$store.getters["cart/GET_CART"]), 14)
		},
		ChangeShowPricing() {
			this.$store.dispatch('cart/SET_SHOW_PRICING', this.showPricing)
			EventBus.$emit('showPricingChanged')
		},
	},
	computed: {
		showPrice() {
			return this.$store.getters['cart/GET_SHOW_PRICING']
		}
	},
	mounted() {
		this.getPDF()
		this.showPricing = this.$store.getters['cart/GET_SHOW_PRICING']
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.part-pdf {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background-color: white;
		border: 2px solid black;
		border-radius: 5px;
	}

	.pdf-display {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		width: 100%;
		padding: 5px;
		overflow: hidden;
	}

	.pdf-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		width: 100%;
		height: 100%;;
		margin-right: 5px;
		overflow: hidden;
	}

	.viewer {
		width: 100%;
		height: 100vh;
		overflow: hidden;
	}

	.parts-list {
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		min-width: 350px;
		width: 35vw;
		height: 100%;
		padding: 5px;
		border: 1px solid silver;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.part-line {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.search-fields {
		display: flex;
		padding: 5px;
		height: 100%;
		align-items: center;
	}
	
	.part-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 5px;
		padding: 2px;
		background-color: #F0FFF0;
		border: 1px solid black;
		border-radius: 5px;
	}

    .centered-input >>> input {
    	text-align: center
    }

	.price {
		min-width: 30%;
		background-color: #ccffcc;
		border: 1px solid gray; 
		border-radius: 5px;
		padding: 5px;

	}
</style>