<template>
	<div class="view-order">
		<!-- Title Row -->
		<div class="row"
			style="align-items: center;">
			<!-- Order Number Title -->
			<div class="title">
				Order #{{order.id}} 
			</div>

			<!-- Order Status Tracking Tooltip -->
			<v-tooltip
				:disabled="logs.length == 0"
				right
				color="rgba(0,0,0,0)">
				<template v-slot:activator="{ on, attrs }">
					<div class="pointer"
						v-bind="attrs"
						v-on="on">
						&nbsp;Order Status Tracking 
					</div>
				</template>

				<!-- Order Status Tooltip Pop-Up -->
				<div class="tooltip">
					<div class="row">
						<h3>Order Status Changes:</h3>
					</div>

					<v-divider style="border-color: black;"/>

					<div class="row"
						style="align-items: center;"
						v-for="log, index of logs"
						:key="index">
						<v-icon
							medium>
							update
						</v-icon>&nbsp;

						<div style="font-size: 1.5vh;">
							{{log.message}} by {{log.user}} on {{log.date}}
						</div>
					</div>

				</div>
			</v-tooltip>

			<v-btn
				style="margin-left: 20px;"
				color="warning"
				@click="generatePDF()">
				Generate PDF
			</v-btn>

			<v-spacer />
			
			<!-- Order Status Display, Right -->
			<div class="title">
				Order Status: {{ order.status }}
			</div>
		</div>

		<v-divider style="border-color: black;"/>

		<!-- Order Address & Item Details. Also Container for PDF generation -->
		<div class="column"
			ref="order">
			<!-- Delivery Row -->
			<div class="row"
				style="margin-top: 5px; flex-shrink: 1;">
				<div class="dealer-info">
					<h3>Sold To:</h3>
					<div style="border: 1px solid silver; width: 100%;"></div>
					<h4>{{ dealer.name }}</h4>
					<span>{{ order.address1 }}</span>
					<span>{{ order.address2 }}</span>
					<span>{{ order.address3 }}</span>
					<span>{{ order.city }}, {{ order.state }}, {{ order.country }}</span>
					<span>{{ order.postal }}</span>
				</div>

				<!-- Ship to dealer Info -->
				<div class="ship-info"
					v-if="!order.dropship">
					<h3>Ship To:</h3>
					<div style="border: 1px solid silver; width: 100%;"></div>
					<v-divider style="border-color: black;"/>
					<h4>{{ dealer.name }}</h4>
					<span>{{ order.address1 }}</span>
					<span>{{ order.address2 }}</span>
					<span>{{ order.address3 }}</span>
					<span>{{ order.city }}, {{ order.state }}, {{ order.country }}</span>
					<span>{{ order.postal }}</span>
					
					<div style="border: 1px solid silver; width: 100%;"></div>
					<span><b>Contact:</b> {{ order.contactname }}</span>

					<div class="row"
						style="padding: 0px;">
						<div style="width: 50%; text-align: left;">
							<b>Phone:</b> {{ order.contactphone }}
						</div> 
						
						<div style="width: 50%; text-align: right;">
							<b>Email:</b> {{ order.contactemail }}
						</div>
					</div>
				</div>

				<!-- Ship to dropship info -->
				<div class="ship-info"
					v-else>
					<h3>Drop Ship To:</h3>
					<div style="border: 1px solid silver; width: 100%;"></div>
					<v-divider style="border-color: black;"/>
					<h4>{{ order.contactname }}</h4>
					<span>{{ order.dropshipaddress1 }}</span>
					<span>{{ order.dropshipaddress2 }}</span>
					<span>{{ order.dropshipaddress3 }}</span>
					<span>{{ order.dropshipcity }}, {{ order.state }}, {{ order.country }}</span>
					<span>{{ order.dropshippostal }}</span>
					<div style="border: 1px solid silver; width: 100%;"></div>
					<div class="row">
						<div style="width: 50%; text-align: left;">
							<b>Phone:</b> {{ order.contactphone }}
						</div> 
						
						<div style="width: 50%; text-align: right;">
							<b>Email:</b> {{ order.contactemail }}
						</div>
					</div>
				</div>
			</div>

			<v-divider style="border-color: black;"/>

			<!-- Parts Table Section -->
			<div class="part-list">
				<table>
					<!-- Table Header-->
					<thead class="fix-table-head">
						<td style="width: 5%; text-align: center;"><b>Quantity</b></td>
						<td style="width: 20%; text-align: center;"><b>Part Number</b></td>
						<td style="width: 35%; text-align: center;"><b>Description</b></td>
						<td style="width: 10%; text-align: center;"><b>Dealer</b></td>
						<td style="width: 10%; text-align: center;"><b>Retail</b></td>
						<td style="width: 10%; text-align: center;"><b>Dealer Total</b></td>
					</thead>

					<!-- Parts List Rows -->
					<tr
						v-for="line, index in parts"
						:key="index">
						<td style=" text-align: center;">
							{{ line.quantity }}
						</td>
						<td>
							{{ line.part }}
						</td>
						<td>
							{{ line.description }}
						</td>
						<td style="text-align: right;">
							${{ line.dealer.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
						</td>
						<td style="text-align: right;">
							${{ line.retail.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
						</td>
						<td style="text-align: right;">
							${{ (line.dealer * line.quantity).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
						</td>
					</tr>

					<!-- Dropship Row -->
					<tr style="background-color: blanchedalmond;"
						v-if="order.dropship">
						<td style=" text-align: center;">
							<b>1</b>
						</td>
						<td>
							<b>SURCHARGE</b>
						</td>
						<td>
							<b>DROPSHIP ORDER DIRECT TO CUSTOMER FEE</b>
						</td>
						<td style="text-align: right;">
							<b>$100.00</b>
						</td>
						<td style="text-align: right;">
							<b>$100.00</b>
						</td>
						<td style="text-align: right;">
							<b>$100.00</b>
						</td>
					</tr>
					
					<!-- Totals Table Footer -->
					<tfoot class="fix-table-foot">
						<tr>
							<td style="text-align: center;">
								{{ itemTotal }}
							</td>
							<td style="text-align: center;">
							</td>
							<td></td>
							<td></td>
							<td style="text-align: right;">
								<b>Order Total:</b>
							</td>
							<td style="text-align: right;">
								${{ orderTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { jsPDF } from "jspdf"

export default {
	name: 'ViewOrder',
	components: {
	},
	props: {
		order: Object,
		dealer: Object,
		logs: Array,
		parts: Array,
	},
	data () {
		return {
		}
	},
	methods: {
		generatePDF() {
			var pdf = new jsPDF({
				orientation: "p",
      			unit: "pt",
      			format: "letter",
			})

			var source = this.$refs.order

			pdf.html(source, {
				margin: [20, 20, 20, 20],
				autoPaging: 'text',
				html2canvas: {
					allowTaint: true,
					letterRendering: true,
					logging: false,
					scale: 0.42, // Adjust the scale to fit content
				},
				callback: function () {
					window.open(pdf.output('bloburl'));
				}				
			})
		}
	},
	computed: {
		orderTotal() {
			var total = 0.00

			for (var line of this.parts) {
				total += line.dealer * line.quantity
			}

			if (this.order.dropship) {
				total += 100.00
			}

			return total.toFixed(2)
		},
		itemTotal() {
			var total = 0

			for (var line of this.parts) {
				total += line.quantity
			}

			if (this.order.dropship) {
				total += 1
			}

			return total
		},
	},
	mounted() {
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.view-order {
		display: flex;
		flex-direction: column;
		font-size: 1.5vh;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
		padding: 5px;
	}

	.column {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.dealer-info {
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 50%;
		padding: 5px;
		margin-right: 5px;
		border: 1px solid silver;
		border-radius: 5px;
		background-color: azure;
	}

	.ship-info {
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 50%;
		padding: 5px;
		border: 1px solid silver;
		border-radius: 5px;
		background-color: azure;
	}

	.part-list {
		height: 100%;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;

	}

	.totals {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	tr, td {
		padding: 5px;
		border: 1px solid black;
	}	

	table {
		width: 100%;
	}

	.fix-table-head {
		position: sticky;
		top: 0;
		background-color:lightgreen;
	}
        
	.fix-table-foot {
		position: sticky;
		bottom: 0;
		background-color: #ccffff;
	}

	.tooltip {
		width: 30wv;
		max-height: 70vh;
		padding: 5px;
		border: 1px solid gray;
		border-radius: 5px;
		background-color: aliceblue;
		color: black;
		font-size: 1.1em;
	}

	.pointer {
		font-size: 1.5vh;
		color: green;
	}

	.pointer:hover {
		cursor: pointer;
		color: rgb(27, 78, 136);
	}

	.cart-badge {
		float:left;
	}
</style>