<template>
	<div class="build-sheet"
		id="build"
		ref="build">
		<div class="top-html"
				ref="title">
			<div class="title">
				<div class="title-image-container">
					<img
						class="title-image" 
						ref="image"
						src="../../assets/SUPREME-LOGO.png" />
				</div>

				<v-spacer />
				
				<h3>Supreme International</h3>

				<v-spacer />
			</div>

			<div class="unit">
				<h3>Serial: {{ this.serial }}</h3>
				<SerialDecoder :ser="this.serial" />
			</div>
		</div>


		<div class="unit-container">
			<div class="configuration">
				<div style="width: 100%;"
					v-for="category, index in categories"
					:key="'cat_' + index">
					<div class="category break-avoid"
						v-if="category.results.length > 0">
						<h3>{{ category.name }}:</h3>
						<table :id="'category-table' + index" class="category-table">
							<thead>
								<tr>
									<th colspan="3" style="text-align: left; font-size: 1.5em;">
										<h3>{{ category.name }}:</h3>
									</th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							
							<tr style="background-color:lightsteelblue;">
								<td style="min-width: 100px; text-align: center; font-weight: 600;">
									<h4>Quantity</h4>
								</td>
							
								<td style="width: 20%; text-align: center; font-weight: 600;">
									<h4>Part Number</h4>
								</td>
							
								<td style="width: 70%; padding-left: 5px; font-weight: 600;">
									<h4>Description</h4>
								</td>
							</tr>

							<tr
								v-for="part, pindex in category.results"
								:key="'part_' + pindex">
								<td style="width: 10%; text-align: center;">
									{{ part.quantity }}
								</td>
							
								<td style="width: 20%; text-align: center;">
									{{ part.part }}
								</td>
							
								<td style="width: 70%; padding-left: 5px;">
									{{ part.description }}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { jsPDF } from "jspdf"
import { autoTable } from 'jspdf-autotable'

import SerialDecoder from './serial_decoder'		// Imports the serial number decoder component displayed in top right of serial search.

export default {
	name: 'BuildSheet',
	components: {
		SerialDecoder
	},
	props: {
		categories: Array,
		serial: String,
	},
	data () {
		return {
			processPDF: true
		}
	},
	methods: {
		generatePDF() {
			var pdf = new jsPDF({
				orientation: "p",
	  			unit: "pt",
	  			format: "a4",
			})

			//var source = this.$refs.build
			var source = this.$refs.title
			
			const opt = {
				margin: [0, 0, 0, 0],
				pagebreak: {
					mode: 'avoid-all',
					before: '.break-before',
					after: '.break-after',
					avoid: '.break-avoid',
				},
				html2canvas: {
					y: 0,
					x: 0,
					width: this.$refs.clientWidth,
					height: this.$refs.clientHeight,
					allowTaint: false,
					letterRendering: true,
					logging: false,
					dpi: 500,
					scale: 1, // Adjust the scale to fit content
				},
				callback: (function () {
					pdf.text("", 0, 50)
					var tables = document.getElementsByClassName("category-table")

					for (var table of tables) {
						autoTable(pdf, {
							html: '#' + table.id,
							margin: [95, 10, 20, 30],
							pageBreak: 'avoid',
							useCss: true,
							columnStyles: {
								0: { maxCellWidth: 100, cellWidth: 50, minCellHeight: 20, },
								1: { maxCellWidth: 150, CellWidth: 100, minCellHeight: 20, },
								2: { cellWidth: 400, CellHeight: 20, },
							},
							willDrawPage: ((data) => {
								//console.log(data)
							}).bind(pdf, this),
						})
					}

					window.open(pdf.output('bloburl'))
					this.$emit('complete', false)
				}).bind(this)
			}

			pdf.html(source, opt)
		},
	},
	computed: {
	},
	mounted() {
		this.generatePDF()
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.build-sheet {
		max-width: 600px;
		z-index: -1;
		display: flex;
		flex-direction: column;
		background-color: white;
	}

	.title-image-container {
		float: left;
		height: 40px;
	}

	.title {
		font-size: .5em;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 16pt;
		width: 100%;
		max-height: 50px;
		padding: 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #cf2e2e;
		color: white;
	}

	.title-image {
		position: absolute;
		background-color: #cf2e2e;
		height: 40px;
		object-fit: contain;
	}

	.unit {
		width: 300px;
		max-width: 300px;
		margin-bottom: 5px;
		border: 1px solid silver;
		border-radius: 5px;
		font-size: 0.5em;
		padding: 5px;
		margin-top: 5px;
		margin-left: 5px;
	}

	.unit-container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: 5px;
	}

	.configuration {
		display: flex;
		height: 500px;
		width: 100%;
		flex-direction: column;
		flex-grow: 1;
		align-items: start;
		border-radius: 5px;
		font-size: .5em;
	}

	.category {
		display: flex;
		flex-direction: column;
		width: 100%;
		border-radius: 5px;
		margin-bottom: 5px;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		min-height: 40px;
		margin-bottom: 5px;
	}

	table tr {
		width: 100%;
		border: 1px solid silver;
		margin: 0px;
	}

	td {
		border: 1px solid silver;
		margin: 0px;
	}

	.category-table {
		width: 100%;
	}

	.break-before {
		page-break-before: always;
		page-break-after: avoid;
		page-break-inside: avoid;
	}

	.break-after {
		page-break-before: avoid;
		page-break-after: always;
		page-break-inside: avoid;
	}

	.break-avoid {
		page-break-before: avoid;
		page-break-after: avoid;
		page-break-inside: avoid;
	}

</style>