<template>
	<div class="update">
		<div class="title centered">
			New Update Information:
		</div>

		<div class="information">
			<!--
			<div class="title-small">
				Basic Usage:
			</div>  
			
			<v-divider />
			<ul>
				<li><b>There are different functions under the page banner you can select from (Serial Search, Parts Search, and Documents)</b></li>
				<li><b>Serial Search Instructions:</b></li>
				<ul>
					<li>Make sure you are on the Serial Search tab.</li>
					<li>On the top left you can enter a serial number in and click search or hit the enter key. Serials can be partial and will show a list of matches if any exist.</li>
					<li>Curently most units older than 2014 and newer than 2023 don't work but as we verify units we are adding 2013 and the most recent up to 2025.</li>
					<li>Once a serial number has been successfully searched, you will see a basic serial information decoded on the top right.</li>
					<li>Below the search area you will find a list of categories when you successfully load a unit. You can expand these categories by clicking on them.</li>
					<ul>
						<li>If a part has sub-parts listed you can show them by clicking the <v-icon color="success">mdi-arrow-down-drop-circle</v-icon> beside the image.</li>
						<li>If a part number has an image associated with it, you will be able to see an image preview if you hover over it.</li>
						<li>If a part has a PDF associated to it there will be a <v-icon>mdi-file-pdf-box</v-icon> icon to the right of the part number that can be clicked to display the PDF.</li>
						<li>If a number is red, that means it is generally unpurchasable. In these cases there will usually be a supersede, container for sub-parts, or it is there for informational purposes only.</li>
						<li>If a number has any supersedes, you can get a list of sub-parts by clicking on it.</li>
					</ul>
					<li>Once you get to the part you want, you can copy the part number and paste it into CADshare's Parts Search functionality to get pricing and availability. 
						Unfortunately there is currently no tie in between this serial number tool and CADshare. We are hoping to make this process easier in the future.</li>
					<li><span style="color: red;"><b>If something seems off about the information (Saying 12T in the description when looking at a 6T, Conflicting top level assemblies, etc.) 
						feel free to call or E-mail. This tool is still a work in progress and there were errors in the data that we are working to correct.</b></span></li>
				</ul>
				<li><b>Part Search Instructions:</b></li>
				<ul>
					<li>Make sure you are on the Part Search tab.</li>
					<li>Type a part number into the search box on the top right and click search or hit enter. Partial searches are allowed.</li>
					<li>If the search has multiple results a window will pop up asking you to select which part you were looking for. Find the one you want and click on it.</li>
					<li>Image, basic part information, notes and sub-components will be displayed if available.</li>
					<li><span style="color: red;"><b>Not all parts are in the system. We are adding what we think are the more commonly used parts and their sub-components first.</b></span></li>
				</ul>
			</ul><br> -->

		<!-- Start Current Changes -->
			<div class="title-small">
				<span>
					<b>New in version {{ this.version }} <span style="font-size: 10pt;">(04/03/2025)</span>:&nbsp;</b>
				</span>
				
				<v-spacer />

				<span style="font-size: 12pt;">Please report bugs or issues to <a href src="mailto:parts@supremeinternational.com">parts@supremeinternational.com</a></span><br>
			</div>

			<v-divider />

			<ul>
				<li>Added a generic parts catalogue for finding parts for units by type, size, category and serial range. This is currently a work in progress so if you find any missing options or parts please let us know. Also some of the larger Supeme Mechanical Stationaries, Supreme Hydrostatic Stationaries, Segue Stationaries and Delivery Boxes may not yet be set up as of 04/03/2025.</li>
				<li>Added funtionality to allow multiple images per part number. In part search screen you can click on the image preview to get a larger picture that can be cycled through if there are multiple images.</li>
				<li>Added ability to search for parts by description. To do a description search you toggle the switch beside the part search input.</li>
				<li>Added first iteration of a "Where Used" feature. You can click the <v-icon color="#2F4F4F">mdi-database-search</v-icon> icon next to the part number in part search to get a list of units and asemblies where the part was used. Clicking on the assembly will load it in the parts search for you. Currently clicking the unit does nothing.</li>
				<li>Added basic filter to the generic parts catalogue typing a keyword in the filter results box will narrow down the choices.</li>
				<li>Added first iteration of the "Build Sheet" function for serial search. If a unit is in verified status an orange "Build Sheet" button will appear next to the search. When clicked this should generate a PDF of the top level configuration of that specific unit and open it in a new tab which can be downloaded and printed.</li>
				<li>Lots of bug fixes and visual optimizations.</li>
			</ul><br />

		<!-- End Current Changes -->

			<div class="title-small">
				<b>New in version 2.0.0:</b>
			</div>

			<ul>
				<li>Changed the Parts Search layout to give the sub-components more space.</li>
				<li>Changed a lot of layout and sizing to better fit lower resolution screens.</li>
				<li>Changed the tabs to be more intuitive and visible.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.9.2:</b>
			</div>

			<v-divider />

			<ul>
				<li>Added ability to view PDFs for parts that have been set up with a <v-icon>mdi-file-pdf-box</v-icon> shown beside the part number. Setting these up will take time and be a long term work in process.</li>
				<li>Added ability to set parts lists to the attached part PDFs with quantity, part number, description and PDF part id shown.</li>
				<li>Added ability to close PDF display that pops up by clicking the <v-icon color="error">mdi-close-circle</v-icon> button in the top right or pressing the escape key on your keyboard.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.9.1:</b>
			</div>

			<v-divider />

			<ul>
				<li>Added partial serial number searching. A window will now pop up if you have a partial match. You can then click on a match to load it.</li>
				<li>Added the ability to click on a part number or any of it's supersedes to automatically search that in the parts search tab.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.9.0:</b>
			</div>

			<v-divider />

			<ul>
				<li>Added Document section. Documents can be found with the tree to narrow them down.</li>
				<li>Documents can have the tree narrowed down by typing in the filter documents field.</li>
				<li>Clicking on a document will load a preview in the right side.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.8.7:</b>
			</div>

			<v-divider />

			<ul>
				<li>Units can now have unit specific notes. This will be useful to make sure anything out of the ordinary about a unit can be easily communicated.</li>
				<li>Both parts and serial search now retain the last 10 successful searches to make going between units easier.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.8.6:</b>
			</div>

			<v-divider />

			<ul>
				<li>Changed supersedes to have hoverable pictures and information just like normal part numbers.</li>
				<li>In the Parts Search section, you can click on the superseded numbers in the top section to change to that part.</li>
				<li>In the Parts Search section, you can click on the part numbers and superseded numbers in the Sub-Components section to switch to that part.</li>
				<li>Changed the dropdown arows to green to be more noticable.</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { version } from '../../package.json'

export default {
	name: 'Update',
	components: {
	},
	data() {
		return {
			version: version,
		}
	},
	props: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.update {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: white;
	border: 2px solid darkgrey;
	border-radius: 5px;
}

.centered {
	text-align: center;
}

.title {
	font-size: 24pt;
	border: 1px solid darkgrey;
	background-color: lightblue;
	padding: 5px;
	width: 100%;
}

.title-small {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	font-size: 16pt;
	text-align: left;
}

.information {
	flex-grow: 1;
	padding: 5px;
	width: 100%;
	border: 1px solid darkgrey;
	overflow-y: scroll;
	overflow-x: hidden;
	text-align: left;
}
</style>