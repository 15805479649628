import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store/'
import vuetify from '@/plugins/vuetify'
import vClickOutside from 'v-click-outside'
import * as firebase from 'firebase/app'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDZE3AY1d8xoOjpQintgLSbOg5t-iFny5g",
	authDomain: "supreme-dealer-tools-login.firebaseapp.com",
	projectId: "supreme-dealer-tools-login",
	storageBucket: "supreme-dealer-tools-login.firebasestorage.app",
	messagingSenderId: "663479072579",
	appId: "1:663479072579:web:f806e6ec497c17d97ee1f6",
	measurementId: "G-F54YSXYLMK"
}

firebase.initializeApp(firebaseConfig)

fetch('./config.json')
	.then((response) => response.json())
		.then((config) => {
			var VueCookie = require('vue-cookie')
			Vue.use(vClickOutside)
			Vue.use(VueCookie)
			Vue.config.productionTip = false

			Vue.prototype.$config = config
			Vue.prototype.$api = config.api
			Vue.prototype.$dealer = config.dealer

			new Vue({
				store,
				vuetify,
				render: (h) => h(App)
			}).$mount("#app")
		})