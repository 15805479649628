<template>
    <div class="where-used">
		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<div class="progress">
				<v-progress-circular
					:size="100"
					color="primary"
					indeterminate/>
			</div>
		</div>

		<div class="title">
			Where Used:
			
			<div class="close">
				<v-btn
					icon
					@click="closeWhereUsed()">
					<v-icon
						color="error">
						mdi-close-circle
					</v-icon>
				</v-btn>
			</div>
		</div>

		<div class="panels">
			<!-- Unit List -->
			<div class="lists">
				<h4>Units:</h4>
				<div class="list-container">
					<div v-if="!loading && units.length == 0">
						<h3>No units found that use {{ part }}.</h3>
					</div>

					<div class="list-row"
						v-for="unit, index in units"
						:key="'unit_' + index">
						{{ unit }}
					</div>
				</div>
			</div>

			<!-- Spacer -->
			<div style="width: 5px;" />

			<!-- Assembly List -->
			<div class="lists"
				style="width: 70%;">
				<h4>Assemblies:</h4>
				<div class="list-container">
					<div v-if="!loading && assemblies.length == 0">
						<h3>No assemblies found that use {{ part }}.</h3>
					</div>

					<div class="list-row selectable"
						v-for="assembly, index in assemblies"
						:key="'assy_' + index"
						@click="partClicked(assembly.id, assembly.part)">
						{{ assembly.part }} - {{ assembly.description }}
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import { EventBus } from '../../javascript/event-bus'

export default {
    name: 'WhereUsed',
    components: {
    },
    props: {
        part: String,
    },
    data () {
        return {
            loading: false,

			units: [],
			assemblies: [],
        }
    },
    methods: {
        getAssemblies() {
			var formData = new FormData();

			this.results = []
			this.loading = true;

            formData.append("part", this.part)

			axios.post(`${this.$config.api}/api.php?cmd=getWhereUsed`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED
					if (!response.data.success){
						this.loading = false;
						console.log(response.data)
						return
					}

					const comparator = (a, b) => {
						return a.toString().localeCompare(b.toString(), 'en', { numeric: true })
					}					

					this.units = response.data.units
					this.units.sort(comparator)

					this.assemblies = response.data.assemblies
					this.assemblies.sort(comparator)

					this.loading = false
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
        },
		partClicked(id, part) {
			if (part.length > 0) {
				this.$parent.partClicked(id, part)
			}
			
			this.closeWhereUsed()
		},
		closeWhereUsed() {
			EventBus.$emit('whereUsed', null)
		},
    },
    computed: {
    },
	mounted() {
		this.getAssemblies()
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.where-used {
		display: flex;
		flex-direction: column;
		height: 50%;
		width: 80%;
		overflow: hidden;
		background-color: white;
		border: 2px solid darkgrey;
		border-radius: 5px;
	}

	.centered {
		text-align: center;
	}

	.title {
		font-size: 24pt;
		border: 1px solid darkgrey;
		background-color: lightblue;
		padding: 5px;
		width: 100%;
	}

	.loading {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 100;
	}

	.progress {
		position: relative;
		top: 40%;
	}

	.panels {
		display: flex;
		flex-direction: row;
		text-align: start;
		width: 100%;
		height: 100%;
		overflow: hidden;
		padding: 5px;
	}

	.lists {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		min-width: 300px;
		height: 100%;
		overflow: hidden;
		padding: 5px;
		border: 1px solid silver;
		border-radius: 5px;

	}

	.list-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: start;
		width: 100%;
		padding: 5px;
		border: 1px solid silver;
		border-radius: 5px;
		margin-bottom: 5px;
	}

	.list-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top: 1px solid silver;
		padding-top: 5px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.close {
		float: right;
	}

	.selectable:hover {
		background-color: lightcyan;
		cursor: pointer;
	}
</style>